import { signInWithEmailAndPassword } from 'firebase/auth';
import React from 'react'
import { auth } from '../../../firebase-config';
import { LayoutFS } from '../../locust/composables/Layout';
import {Signin} from '../../locust/components/Signin'
import { FACEBOOK_BLACK } from '../../assets/colors/Colors';
import { Button } from '@mui/material';
import XY from '../../locust/composables/XY';
import SpacerV from '../../locust/sections/SpacerV';

const SigninPage = () => {

    const [awaitingSignin, setAwaitingSignin] = React.useState(false)
    const [username, setUsername] = React.useState("")
    const [password, setPassword] = React.useState("")
    
    return (
        
            <>
            <SpacerV h={50}/>
            <XY>
                <Button
                    
                    style={{ 
                        width : 350, 
                        marginLeft : 10, 
                        marginRight : 10,
                        height : 55, 
                        backgroundColor: FACEBOOK_BLACK, 
                        color: 'white',
                    }}
                    type="submit"
                    onClick={()=>{
                        auth.signOut().then(()=>{
                            console.log('log out success!')
                        }).catch(()=>{
                            console.log('log out fail!')
                        })
                    }}
                    
                    variant="contained"
                
                >Log Out</Button>
            </XY>
            </>
    )
}

export default SigninPage;